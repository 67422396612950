export const FILTERS_TITLE = (scope: string): string => {
  switch (scope) {
    case "stampInClass":
      return "Timbratura - Inizio";
    case "stampOutClass":
      return "Timbratura - Fine";
    case "gpsInClass":
      return "Geo - Inizio";
    case "gpsOutClass":
      return "Geo - Fine";
    case "keepAliveClass":
      return "Check";
    case "alertClass":
      return "Anomalie";
    default:
      return "";
  }
};
