



































import { Component, Prop, PropSync, Vue } from "vue-property-decorator";
import {
  ArchivesFilters,
  ArchivesFiltersValue,
  SelectFilter,
} from "@/models/utils/archives-filters.interface";
import { ListFilter } from "@/models/entities/list-filter.interface";
import { FILTERS_TITLE } from "@/helpers/filters-title";
import { CBtn } from "@/models/utils/c-btn.interface";

const ArchivedSelectFilters = () =>
  import(
    /* webpackChunkName: "ArchivedSelectFilters" */ "./ArchivedSelectFilters.vue"
  );

@Component({
  components: {
    "archived-select-filters": ArchivedSelectFilters,
  },
})
export default class ArchivedFilters extends Vue {
  @Prop({
    required: false,
    default: () => {
      return {
        btnFilters: {} as { [id: string]: ListFilter[] },
        selectFilters: {} as SelectFilter,
      };
    },
    type: Object,
  })
  private filters: ArchivesFilters;
  @PropSync("selected", { required: true, type: Object })
  private _selected: ArchivesFiltersValue;

  private excelBtn: Omit<CBtn, "type"> = {
    btnClass: "c-btn-outlined",
    label: "Scarica Excel",
    labelClass: "c-label-dark",
    color: "var(--v-light-base)",
    icon: {
      name: "excel",
      fill: "var(--v-primary-lighten2)",
      height: 24,
      width: 24,
      iconStyle: "",
    },
  };

  private get filtersScope(): string[] {
    return this.filters &&
      this.filters.btnFilters &&
      Object.keys(this.filters.btnFilters).length !== 0
      ? Object.keys(this.filters.btnFilters)
      : [];
  }

  private getFilterTitle(scope: string): string {
    return FILTERS_TITLE(scope);
  }

  private onClick(value: string | null, scope: string): void {
    this.$set(this._selected, scope, value);
    this.$emit("on-click", false);
  }
}
